<template>
  <b10-base>
    <b10-toolbar
      :title="title"
    />
    <b10-page-content>
      <b10-view-summary
        :item="formattedItem"
        :showing.sync="showingDialogs.moreInfo"
        :loading-data="loadingData"
        :show-alerts="!routeFromBrowserHistory"
        :more-info-in-dialog="false"
        @click-row="clickSummaryRow"
      />
    </b10-page-content>
  </b10-base>
</template>

<script>
import _ from '@/utils/lodash'
import { viewPageMixin } from '@/mixins/viewPageMixin'
import { nonEmpty } from '@/utils/templates'
import Data from './PresenciaViewData'
import { isSameDay } from '@/utils/date'
import { getLatLongURL } from '@/utils/maps'

export default {
  mixins: [viewPageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  data () {
    return {
      moreInfoRows: {
        entrada: {
          title: 'Entrada',
          rows: [
            { name: 'fentrada', filter: this.$options.filters.shortDateTime },
            { name: 'observaciones_entrada' },
            { name: 'punto_entrada_codigo' },
            { name: 'punto_entrada_descripcion' },
            { name: 'geolocalizacionEntrada', label: 'Geolocalización', clickable: true },
          ],
        },
        salida: {
          title: 'Salida',
          rows: [
            { name: 'fsalida', filter: this.$options.filters.shortDateTime },
            { name: 'observaciones_salida' },
            { name: 'punto_salida_codigo' },
            { name: 'punto_salida_descripcion' },
            { name: 'geolocalizacionSalida', label: 'Geolocalización', clickable: true },
          ],
        },
      },
      showingDialogs: {
        moreInfo: false,
      },
    }
  },
  computed: {
    formattedItem () {
      if (!_.isEmpty(this.item.dataset)) {
        const item = _.cloneDeep(this.item.dataset)
        item.id = item.idpresencia
        if (item.latitud_entrada) {
          item.geolocalizacionEntrada = `${item.latitud_entrada}, ${item.longitud_entrada}`
        }
        if (item.latitud_salida) {
          item.geolocalizacionSalida = `${item.latitud_salida}, ${item.longitud_salida}`
        }
        item.fechaEntrada = `${this.$options.filters.shortDateTime(item.fentrada)}`
        if (item.fsalida) {
          if (isSameDay(item.fentrada, item.fsalida)) {
            item.fechaSalida = `${this.$options.filters.shortTime(item.fsalida)}`
          } else {
            item.fechaSalida = `${this.$options.filters.shortDateTime(item.fsalida)}`
          }
          item.title = this.$options.filters.linebreaksBr(nonEmpty`
            ${item.fechaEntrada} hasta ${item.fechaSalida}`)
        } else {
          item.title = this.$options.filters.linebreaksBr(nonEmpty`
            ${item.fechaEntrada} - <span class="red--text">SIN SALIDA</span>`)
        }
        item.subtitle = [item.observaciones_entrada, item.observaciones_salida]
        item.moreInfo = this.buildMoreInfo(this.item.metadata)
        return item
      } else {
        return {}
      }
    },
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      this.title = 'Entrada/salida'
      await this.loadItem()
    },
    async loadItem () {
      this.loadingData = true
      try {
        const [dataset, metadata] = await Data.selectPresencia(this, this.routeParams.idpresencia)
        if (dataset) {
          this.$set(this.item, 'dataset', dataset)
          this.$set(this.item, 'metadata', metadata)
        } else {
          this.itemNotFound()
        }
      } finally {
        this.loadingData = false
      }
    },
    async clickSummaryRow (row) {
      if (row.name === 'geolocalizacionEntrada') {
        window.open(
          getLatLongURL(this.item.dataset.latitud_entrada, this.item.dataset.longitud_entrada),
          '_blank'
        )
      } else if (row.name === 'geolocalizacionSalida') {
        window.open(
          getLatLongURL(this.item.dataset.latitud_salida, this.item.dataset.longitud_salida),
          '_blank'
        )
      }
    },
  }
}
</script>
